import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiCustomerDetails } from "services/RegistrationService";

export const getCustomerDetails = createAsyncThunk(
  "customer_details",
  async (id) => {
    const { data } = await apiCustomerDetails({ customer_id: id });
  }
);

export const sessionSlice = createSlice({
  name: "auth/session",
  initialState: {
    token: "",
    number: "",
    signedIn: false,
    referenceId: null,
    status: null,
    name: null,
    customer_id: null,
    user_id: null,
    loan_id: null,
    organization_id: null,
    role_id: null,
    first_time_login: true,
    organization_image: null,
    customerDetails: null,
    email: null,
    organization_type: null,
    user: null,
    product: null,
    productDialog: false,
    productList: [],
    cpoName: null,
    state: null,
  },
  extraReducers: {
    [getCustomerDetails.fulfilled]: (state, action) => {
      state.loan_id = action.payload.loan_id;
      state.status = action.payload.status;
    },
  },
  reducers: {
    onSignInSuccess: (state, action) => {
      state.signedIn = true;
      state.token = action.payload.token;
      state.name = action.payload.name;
      state.status = action.payload.status;
      state.customer_id = action.payload.customer_id;
      state.user_id = action.payload.user_id;
      state.first_time_login = action.payload.first_time_login;
      state.role_id = action.payload.role_id;
      state.email = action.payload.email;
      state.first_name = action.payload.first_name;
      state.last_name = action.payload.last_name;
      state.organization_name = action.payload.organization_name;
      state.headerName = action.payload.headerName;
      state.user = action.payload.user;
    },
    onProductClick: (state, action) => {
      state.product = action.payload;
    },
    onAddCustomerNumber: (state, action) => {
      state.number = action.payload.number;
      state.referenceId = action.payload.referenceId;
    },
    onOtpGenerate: (state, action) => {
      state.number = action.payload.number;
      state.referenceId = action.payload.referenceId;
    },
    onSetStatus: (state, action) => {
      state.status = action.payload;
    },
    onSetState: (state, action) => {
      state.state = action.payload;
    },
    onSaveCustomerId: (state, action) => {
      state.customer_id = action.payload.customer_id;
    },
    onSignOutSuccess: (state) => {
      state.signedIn = false;
      state.token = "";
      state.name = null;
      state.status = null;
      state.customer_id = null;
      state.user_id = null;
      state.first_time_login = null;
      state.role_id = null;
      state.email = null;
      state.first_name = null;
      state.last_name = null;
      state.number = undefined;
      state.referenceId = null;
      state.organization_name = null;
      state.organization_id = null;
      state.product = null;
    },
    setDialog: (state, action) => {
      state.productDialog = action.payload;
    },
    setProductList: (state, action) => {
      state.productList = action.payload;
    },

    setToken: (state, action) => {
      state.token = action.payload;
    },
    setOrganization: (state, action) => {
      state.organization_id = action.payload.id;
      state.organization_image = action.payload.image;
      state.organization_type = action.payload.type;
    },
    setNav: (state, action) => {
      state.nav = action.payload;
    },
    setLoanIdStatus: (state, action) => {
      state.loan_id = action.payload.loan_id;
      state.status = action.payload.status;
    },
    setCustomerDetails: (state, action) => {
      state.customerDetails = action.payload;
    },
  },
});

export const {
  onSignInSuccess,
  onOtpGenerate,
  onSignOutSuccess,
  onAddCustomerNumber,
  onSaveCustomerId,
  setToken,
  setCta,
  onSetStatus,
  setLoanIdStatus,
  setOrganization,
  setCustomerDetails,
  onProductClick,
  setProductList,
  setDialog,
  onSetState,
} = sessionSlice.actions;

export default sessionSlice.reducer;
